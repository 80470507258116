<template>
  <div class="account-avatar">
    <el-upload
      class="avatar-uploader"
      :action="uploadFileUrl"
      drag
      :show-file-list="false"
      :headers="headers"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      <i
        v-if="imageUrl"
        class="el-icon-plus avatar-uploader-icon iconClass"
      ></i>
    </el-upload>
  </div>
</template>

<script>
  // import { getToken } from "@/utils/auth";
  export default {
    props: {
      imgUrl: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        certificateImg: [],
        imageUrl: '',
        uploadFileUrl: process.env.VUE_APP_BASE_API + '/index/index/file', // 上传的图片服务器地址
        headers: {
          // Authorization: "Bearer " + getToken()
        },
      }
    },
    mounted() {
      this.imageUrl = this.imgUrl
    },
    methods: {
      handleAvatarSuccess(res, file) {
        const fileName = file.name
        this.imageUrl = file.response.url
        this.$emit('fileUrl', this.imageUrl)
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg'
        const isPNG = file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJPG && !isPNG) {
          this.$message.error('上传头像图片只能是 JPG或PNG 格式!')
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!')
        }
        return isJPG || (isPNG && isLt2M)
      },
    },
  }
</script>
